<script setup>
import { getBadgeProps } from '@@/components/Common/Badges/BadgeProps';
import { useMetaStore } from '@@/stores/Meta';

const metaStore = useMetaStore();

defineProps({
  ...getBadgeProps([
    'additionalClassNames',
    'backgroundColor',
    'borderColor',
    'color',
    'paddingX',
    'paddingY',
    'shape',
  ]),
  size: {
    type: String,
    default: 'base',
  },
});

const supportArticleUrl = computed(() => metaStore.pricing?.pro_tier_url || '#');
</script>

<template>
  <Badge
    background-color="background-color-saturated-orange"
    color="tw-text-white"
    padding-x="tw-px-2"
    padding-y="tw-py-0.5"
    shape="rounded"
    :size="size"
  >
    <template #default>
      <NuxtLink
        class="hover:tw-no-underline"
        target="_blank"
        :to="supportArticleUrl"
      >
        <span class="tw-font-medium tw-uppercase">
          <slot>Pro Preview</slot>
        </span>
        <font-awesome-icon
          class="tw-ml-1"
          :icon="['fa-solid', 'fa-info-circle']"
        />
      </NuxtLink>
    </template>
  </Badge>
</template>
